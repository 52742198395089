body {
  background: #666 /* #eee */;
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 60px 20px 20px 20px;
}

h1 {
  font-size: 1.4em;
}

ol, ul {
  padding-left: 30px;
}

.map-item {
  display: block;
  margin: -1px;
  padding: 0.4em;
  border: 1px solid;  
  min-width: 160px;
}

.play-button {
  margin-left: 30px;
}

.bottom-margin {
 margin-bottom: 20px;
}

.form-border {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#sprite-sheet {
  display:none; /* ?unhide for saving embedded images */
}

.grid-wrapper,
.map-wrapper,
.cell--button {
  image-rendering: pixelated;
}

.nav-button {
  width: 80px;
}

.grid-wrapper {
  position: absolute;
}

.keys-wrapper {
   position: relative;
    width: 100%;
}

.managemaps-button {
  margin-left: 0.2em;
}

.map-modal__button {
  margin: 0.4em 0.2em 0 0;
}

.map-modal__edit-button {
  float: right;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.trigger--is-empty {
  border: 1px solid #aaa;
}

.trigger--not-empty {
  border: none;
}

.rule {
  background: #fff;
  position: relative;
  margin: 0 14px 11px 0;
  float: left;
  width: 199px;
  height: 131px;
  border-radius: 7px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.rule--header {
    height: 20px;
    width: 100%;
    background:#ddd;
    padding: 1px 5px;
    color: #999;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
}

.rule--number {
  float: left;
}

.rule--menu {
  float: right;
}
.rule--menu-icon, .rule--menu-icon:focus, .rule--menu-icon:hover {
  float: right;
  background-color: unset !important;
  border: none !important;
  box-shadow: none !important;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
.group--menu-icon, .group--menu-icon:focus, .group--menu-icon:hover {
  position: absolute;
  font-size: 24pt;
  top: -70px;
  right: 15px; 
  color: #ffffff;
  background-color: unset !important;
  border: none !important;
  box-shadow: none !important;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

.group--menu-icon.dropdown-toggle::after {
  display: none!important;
}

/* Label/Rule Group */

.label {
  float: left;
  width: 100%;
  height: 100%;
  background: #777;  
  padding: 10px;
  margin: 0 0 1em;
  border-radius: 15px;
}

.label__input {
  float: none;
  background-color: #777;
  border: none;
  width: auto;
  color: #fff;
  padding: 0.4em 1em;
  margin: 0 0 0.4em 0;
  font-size: 1.4em;  
}

.label__input::placeholder {
  color: #ccc;
}

.label__menu {
    position: relative;
    left: -15px;
    top: 5px;
    color: #999;
    padding: 15px 10px;
    cursor: pointer;
    width: 20px;
}

.cell1 {
  position: absolute;
  left: 85px;
  top: 27px;
}

.cell2 {
  position: absolute;
  left: 52px;
  top: 60px;
}

.cell3 {
  position: absolute;
  left: 85px;
  top: 60px;
}

.cell4 {
  position: absolute;
  left: 118px;
  top: 60px;
}

.cell5 {
  position: absolute;
  left: 85px;
  top: 93px;
}

.cell-output {
  position: absolute;
  left: 158px;
  top: 60px;
}

.cell-trigger1 { 
  position: absolute;
  left: 9px;
  top: 60px;
}

.cell-trigger2 { 
  position: absolute;
  left: 9px;
  top: 26px;
}

.cell-trigger3 { 
  position: absolute;
  left: 9px;
  top: 94px;
}

.arrow-wrapper {
  position: absolute;
  left: 158px;
  top: 22px;
}

.sprite--inner {
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 32px;
  height: 32px;
  border: none;
}

.cell--trigger1,
.cell--trigger2,
.cell--trigger3 {
  background-size: contain;
}

.cell-trigger1 .cell--inner,
.cell-trigger2 .cell--inner,
.cell-trigger3 .cell--inner {
  border-radius: 15px;
  background-color: #ddd;
  width: 32px;
  height: 32px;
}

.rule--cell:focus {
  outline: none;
}

.rule--error {
  background: #cc0000!important;
}

.rule--error .rule--header {
  background: #cc0000!important;
  color: #eeeeee;
}

.rule--error .rule__inner {
  background: #969696;
  opacity: 0.4;
}

.rule--disabled {  
  background: #aaaaaa;
}

.rule--disabled .rule__inner {
  background: #969696;
  opacity: 0.4;
}  

.rule--disabled .rule--header {
  background: #969696;
  color: #eee;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.mft {
  display: flex;
  flex-direction: row;
}

.popover {
  max-width: 164px
}

.popover__icon {
  float: left;
  width: 32px;
  height: 32px;
  text-align: center;
  background: rgb(255, 255, 255);
  margin: 4px;  
  padding: 0px;
  border: none;
}

.popover__icon .cell--inner {
  width: 32px;
  height: 32px;
  padding: 0px;  
}

.sprite-palette {
  padding: 11px 0 0 8px;
  position: fixed;
  z-index: 99;
  margin: -11px 0 0 -20px;
  
  height: 100%;
}

.sprite-palette--128 {
  width: 350px; /* Was 73px make custom */
}

.sprite-palette--64 {
  width: 160px;
}

.sprite-palette--32 {
  width: 128px;
}

.sprite-palette--16 {
  width: 85px;
}

.sprite-palette--cell {
  margin: 4px 3px 0 0;
  height: 32px;
  width: 32px;
  float: left;
  transform: scale(0.9);
}

.cell--button {
  /*border: 1px solid #ccc;*/
  border: none;
}

.sprite-palette .cell--button {
  border: none;
}

.map-wrapper {
  position: absolute;
  top: 75px;
}
.map-wrapper--128 {
  margin-left: 350px; 
}
.map-wrapper--64 {
  margin-left: 160px;
}
.map-wrapper--32 {
  margin-left: 128px;
}
.map-wrapper--16 {
  margin-left: 85px;
}

.rules-wrapper--128 {
  padding: 3px 0 0 350px;
}
.rules-wrapper--64 {
  padding: 3px 0 0 160px;
}
.rules-wrapper--32 {
  padding: 3px 0 0 128px;
}
.rules-wrapper--16 {
  padding: 3px 0 0 85px;
}

.rules__button-wrapper {  
  position: relative;
  float: left;
  width: 199px;  
  height: 131px;  
  /*
  border-radius: 7px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background: white;
  */
}
.rules__button-plus {
  position: absolute;  
  top: 20px;
  left: 40px;
  width: 36px;
}
.rules__button-minus {
  position: absolute;
  top: 70px;
  left: 40px;
  width: 36px;
}
.rules__button-plus-plus {
  position: absolute;  
  top: 20px;
  left: 90px;
  width: 45px;
}
.rules__button-minus-minus {
  position: absolute;
  top: 70px;
  left: 90px;
  width: 45px;
}

/* NavBar */

.main-links {
  margin: 0 auto;
}

.main-links .nav-link {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.settings-modal {
  background: white;
  min-height: 498px;
  padding: 1em;
  padding-left: 160px;
  margin-top: -5px;
  margin-right: -20px;
}

.settings-modal--narrow {
  padding-left: 350px!important;
}

.settings-modal .btn {
  margin-right: 1em;
}

.settings-modal__label {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
}

.settings-modal__small-label {
  margin-top: .75rem;
}

.settings-modal__sound-row {
  margin-bottom: 1rem;
}

.settings-modal h5 {
  margin: 20px 0 10px 0;
}

.settings-modal__sound-play-button {
  margin-top: 10px;
}

.form-label {
  margin-top: .5rem;
}

.form-check {
  margin-top: .75rem;
}

.map-select--label {
  color: rgba(255,255,255,.5);
  margin-left: 30px;
  margin-right: 10px;
}

.dropdown-group {
  display: inline-flex;
}

/* Trigger */
.trigger__trigger {
  background-color: #00b0f0!important;
  border-radius: 15px;
  color: #fbfd12;  
}

.trigger__step {
  background-color: #fdfc05!important;
  border-radius: 15px;
}

.trigger__any {
  background-color: #00b0f0!important;  
  color: #fbfd12;
  font-size: 12px;
}

/* Special */

.special__wrapper {
  float: left;
  margin: 2px;
}
.special__wrapper_onrule {
  position: absolute;
  left: 125px;
  top: 101px;
}
.special {
  border: none;
  font-size: 12px;
  width: 65px;
  border-radius: 9px;
  padding: 3px;
}

.special__sound {
  background-color:#fdfc05;
  border: none;
}

.special__trigger {
  background-color: #00b0f0;
  color: #fbfd12;  
}

.special__action {
  background-color: #fe368b;
  color: white;
}

.special__blank {
  background-color: #dddddd;
  color: #dddddd;
  border: 1px solid #aaaaaa;
}

/* Play */

.fullscreen-button {
  display: block;
  font-size: 10pt;
  float: right;
  border-radius: 0 0.25rem 0 0;
}
.restart-button {
  display: block;
  font-size: 10pt;
  float: right;
  border-radius: 0.25rem 0 0 0;
}
.fullscreen-enabled .restart-button {
  display: none;
}

.fullscreen-enabled .fullscreen-button {
  display: none;
}

.fullscreen-enabled {
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-screen__wrappper {    
  position: absolute;
  width: fit-content;   
}

.play-screen {
  display: block;  
}

.play-screen__score {
  padding: 8px;
    background: #007bff;
    color: white;
    border-top-right-radius: 15px
}
